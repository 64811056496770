export function getElementHeight(elem) {
  if (!elem) {
    return;
  }

  return `${elem.clientHeight}px`;
}

export const isBrowser = typeof window !== 'undefined';

export function lerp(start, end, amt) {
  return (1 - amt) * start + amt * end;
}

export function clamp(num, min, max) {
  return Math.min(Math.max(num, min), max);
}

export function wrap(num, min, max) {
  const rangeSize = max - min;
  return ((((num - min) % rangeSize) + rangeSize) % rangeSize) + min;
}
