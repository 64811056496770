import React, { ReactNode, useRef, useEffect } from 'react';
import { animated, config, useChain, useTransition } from 'react-spring';
import { useClickAway, useLockBodyScroll } from 'react-use';
import { Flex } from 'rebass/styled-components';
import styled from 'styled-components';

interface ModalProps {
  transparent?: boolean;
  open: boolean;
  handleClose: Function;
  children: ReactNode;
  scroll: boolean;
}

const Background = styled(animated(Flex))<any>`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 500;
  transform: translate3d(0, 0, 500px);
  overflow: hidden;
  justify-content: center;
`;

const Container = styled(Flex)`
  width: 100vw;
  height: 100vh;
  border-radius: 0;
  z-index: 501;
  flex: 1;
  overflow: hidden;
  transform: translate3d(0, 0, 501px);

  @media (max-width: 750px) {
    width: 100vw;
    height: 100%;
    top: 0;
    border-radius: 0;
  }
`;

const Wrapper = styled(Flex)<any>`
  overflow-y: ${props => (props.scroll ? 'scroll' : 'hidden')};
  overflow-x: hidden;
  height: 100%;
  width: 100%;
`;

const StyledModal = styled(animated(Flex))``;

const Modal: React.FC<ModalProps> = props => {
  const modalRef = useRef(null);

  useEffect(() => {
    document.addEventListener('keydown', handleEscBtn, false);

    return () => {
      document.removeEventListener('keydown', handleEscBtn, false);
    };
  }, []);

  useLockBodyScroll(props.open);

  useClickAway(modalRef, () => {
    props.handleClose();
  });

  const handleEscBtn = e => {
    if (e.keyCode === 27) {
      props.handleClose();
    }
  };

  const springRef = useRef<any>();
  const bgTransition = useTransition(props.open, null, {
    ref: springRef,
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: config.default,
  });

  const transRef = useRef<any>();
  const modalTransition = useTransition(props.open, null, {
    ref: transRef,
    from: { opacity: 0, transform: 'translateY(50px)' },
    enter: { opacity: 1, transform: 'translateY(0px)' },
    leave: { opacity: 0, transform: 'translateY(50px)' },
    config: config.default,
  });

  useChain(props.open ? [springRef, transRef] : [transRef, springRef], [
    0,
    props.open ? 0.1 : 0.4,
  ]);

  return (
    <>
      {bgTransition.map(
        ({ item: bgItem, key: bgKey, props: bgStyles }: any) =>
          bgItem && (
            <Background
              key={bgKey}
              transparent={props.transparent}
              style={bgStyles}
            >
              {modalTransition.map(
                ({ item, key, props: styles }) =>
                  item && (
                    <StyledModal key={key} style={styles}>
                      <Container ref={modalRef}>
                        <Wrapper scroll={props.scroll}>
                          {props.children}
                        </Wrapper>
                      </Container>
                    </StyledModal>
                  )
              )}{' '}
            </Background>
          )
      )}
    </>
  );
};

Modal.defaultProps = {
  transparent: true,
  scroll: true,
};

export default Modal;
