import { motion } from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react';
import { Box, Image } from 'rebass/styled-components';
import styled from 'styled-components';

import { isBrowser } from '../scripts/utils';
import close from '../static/images/icons/close-white.svg';
import pause from '../static/images/icons/pause.svg';
import play from '../static/images/icons/play.svg';
import aboutUsVideo from '../static/videos/about-page-bkg-video.mp4';
import ModalFullscreen from './ModalFullscreen';

const HeroContainer = styled(Box)`
  width: 100%;
  height: 0;
  max-height: 100vh;
  padding-bottom: 45%;
  overflow: hidden;
  position: relative;

  @media (max-width: 1200px) {
    padding-bottom: 55%;
  }

  @media (max-width: 1000px) {
    padding-bottom: 75%;
  }

  @media (max-width: 700px) {
    padding-bottom: 120%;
  }

  @media (max-width: 500px) {
    padding-bottom: 140%;
  }

  button {
    appearance: none;
    background-color: transparent;
  }
`;

const ModalWrapper = styled(Box)`
  width: 100%;
  height: 100%;
  padding: 72px 48px;

  iframe {
    width: 100%;
    height: 100%;
  }

  .modal__close {
    position: absolute;
    top: 24px;
    right: 24px;
    width: 40px;
    height: 40px;
    cursor: pointer;
    transition: transform 300ms cubic-bezier(0.68, 0.55, 0.27, 1);

    &:hover {
      transform: scale(1.1);
    }
  }
`;

const VideoContainer = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;

  &:before {
    content: '';
    background-color: rgba(0, 0, 0, 0.3);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
  }

  .hero__poster {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    min-width: 100%;
    min-height: 100%;
    max-width: 100%;
    max-height: 100%;
    font-family: 'object-fit:cover;object-position:center';
    object-fit: cover;
    object-position: center;
  }

  .hero__video {
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @media (max-width: 700px) {
      display: none;
    }
  }

  .hero__playpause {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    position: absolute;
    right: 60px;
    bottom: 40px;
    border-radius: 100%;
    border: 1px solid rgba(0, 165, 154, 0.75);
    overflow: hidden;
    appearance: none;
    background-color: transparent;
    cursor: pointer;
    z-index: 10;
    transition: transform 500ms cubic-bezier(0.68, 0.55, 0.27, 1);

    &:hover {
      transform: scale(1.05);
    }

    @media (max-width: 1440px) {
      right: 30px;
    }

    @media (max-width: 700px) {
      display: none;
    }

    &.is-paused {
      &:before {
        opacity: 0;
        transform: translate(-50%, -50%) scale(0.5);
      }
      &:after {
        opacity: 1;
        transform: translate(-50%, -50%) scale(1);
      }
    }

    &:before,
    &:after {
      content: '';
      background-size: 100%;
      background-position: center;
      width: 17px;
      height: 20px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform-origin: center;
      transition: all 350ms cubic-bezier(0.68, 0.55, 0.27, 1);
    }

    &:before {
      background-image: url(${pause});
      opacity: 1;
      transform: translate(-50%, -50%) scale(1);
    }

    &:after {
      background-image: url(${play});
      opacity: 0;
      transform: translate(-50%, -50%) scale(1.3);
      margin-left: 3px;
      transition-delay: 50ms;
    }
  }
`;

const Hero = styled(Box)<any>`
  max-width: 1440px;
  min-height: 100%;
  width: 100%;
  position: absolute;
  left: 50%;
  z-index: 10;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 1200px) {
    width: 100%;
  }

  .hero__content {
    position: relative;
    top: 48px;
    z-index: 10;
    color: #ffffff;
    text-align: center;

    @media (max-width: 1200px) {
      left: 0;
    }

    @media (max-width: 1000px) {
      top: 24px;
    }
  }

  .hero__subtitle {
    font-family: 'FFMarkWebProBold', 'Helvetica', Arial, sans-serif;
    font-weight: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: 3px;
    text-transform: uppercase;
    margin-bottom: 32px;

    @media (max-width: 700px) {
      font-size: 13px;
      margin-bottom: 20px;
    }
  }

  .hero__title {
    font-family: 'NoeDisplayRegularItalic', Georgia, serif;
    font-style: normal;
    font-size: 143px;
    line-height: 1;
    font-weight: normal;
    letter-spacing: 0.5px;
    color: #ffffff;
    font-weight: normal;
    margin-top: 0;
    margin-bottom: 24px;

    @media (max-width: 700px) {
      font-size: 100px;
    }

    @media (max-width: 500px) {
      font-size: 81px;
    }
  }

  .watch__button {
    font-family: 'FFMarkWebProLight', 'Helvetica', Arial, sans-serif;
    color: #ffffff;
    font-size: 15px;
    line-height: 1;
    padding: 16px 32px 18px;
    overflow: hidden;
    border-radius: 50px;
    border: 1px solid #ffffff;
    cursor: pointer;
    transition: all 300ms cubic-bezier(0.65, 0.05, 0.36, 1);

    &:hover {
      color: #424143;
      background-color: #ffffff;
    }
  }
`;

const VideoHero: React.FC<any> = ({ data }) => {
  const video = useRef<HTMLVideoElement | null>(null);
  const [isPlaying, setIsPlaying] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isReducedMotion, setIsReducedMotion] = useState(false);

  const {
    video_hero_subtitle,
    video_hero_title,
    video_hero_button_text,
    video_hero_poster_image,
    video_embed,
  } = data;

  useEffect(() => {
    if (
      isBrowser &&
      window.matchMedia('(prefers-reduced-motion: reduce)').matches
    ) {
      setIsReducedMotion(true);
      setIsPlaying(false);
    }
  }, []);

  const handlePlayPause = () => {
    if (isPlaying) {
      video.current?.pause();
      setIsPlaying(false);
    } else {
      video.current?.play();
      setIsPlaying(true);
    }
  };

  const handleModalOpen = () => {
    setIsModalOpen(true);
    setIsPlaying(false);
    video.current?.pause();
  };

  const handleModalClose = () => {
    setIsModalOpen(false);

    if (!isReducedMotion) {
      setIsPlaying(true);
      video.current?.play();
    }
  };

  return (
    <>
      <HeroContainer>
        <VideoContainer>
          <Image
            src={video_hero_poster_image.url}
            className="hero__poster"
            alt="Video Poster"
          />
          <motion.video
            ref={video}
            src={aboutUsVideo}
            poster={video_hero_poster_image.url}
            className="hero__video"
            autoPlay={isPlaying}
            playsInline
            muted
            loop
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          />
          <button
            onClick={handlePlayPause}
            aria-label="Toggle Play Pause"
            className={`hero__playpause ${!isPlaying ? 'is-paused' : ''}`}
          ></button>
        </VideoContainer>
        <Hero mx="auto">
          <Box className="hero__content">
            <div className="hero__subtitle">{video_hero_subtitle}</div>
            <h2 className="hero__title">{video_hero_title}</h2>
            <button
              onClick={handleModalOpen}
              className="watch__button"
              aria-label="Open Modal"
            >
              {video_hero_button_text}
            </button>
          </Box>
        </Hero>
      </HeroContainer>
      <ModalFullscreen
        key="modal"
        open={isModalOpen}
        handleClose={handleModalClose}
        scroll={false}
      >
        <ModalWrapper>
          <div
            dangerouslySetInnerHTML={{ __html: video_embed.html }}
            style={{ height: '100%' }}
          />
          <div onClick={handleModalClose} className="modal__close">
            <Image src={close} alt="Close" />
          </div>
        </ModalWrapper>
      </ModalFullscreen>
    </>
  );
};

export default VideoHero;
