import { graphql } from 'gatsby';
import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { InView } from 'react-intersection-observer';
import { Box, Flex, Image, Text } from 'rebass/styled-components';
import styled from 'styled-components';

import { PrismicText } from '@prismicio/react';

import Layout from '../components/Layout';
import SectionIntro from '../components/SectionIntro';
import VideoHero from '../components/VideoHero';

const Container = styled(Flex)`
  position: relative;
  z-index: 10;
  overflow: hidden;

  &:before {
    content: '';
    width: 100%;
    height: 200px;
    background: linear-gradient(to top, #000, transparent);
    top: -200px;
    z-index: 100;
    transform: translate3d(0, 0, 100px);
    position: absolute;
  }

  > div:nth-child(1) {
    padding: 100px 10% 0;
    margin: 0 auto 200px !important;
    text-align: center;

    @media (min-width: 1441px) {
      padding: 100px 4% 0;
    }
    @media (max-width: 1000px) {
      padding: 100px 8% 0;
    }
    @media (max-width: 750px) {
      padding: 65px 8% 0;
      margin-bottom: 100px !important;
    }

    > div:nth-child(1) {
      h2 {
        font-size: 50px;
        @media (max-width: 750px) {
          font-size: 26px;
        }
      }
      p {
        font-size: 21px;
        padding: 0 10%;
        @media (max-width: 750px) {
          font-size: 17px;
          padding: 0;
        }
      }
    }
  }
`;

const ImageBG = styled.div`
  background-color: #f4f2ef;
  width: 90%;
  height: auto;
  padding-bottom: 90%;
  top: 0;
  left: 7%;
  position: absolute;
  border-radius: 100%;
  z-index: -100;
`;

const Principle = styled(Flex)<any>`
  opacity: ${props => (props.inView ? '1' : '0')};
  transform: ${props => (props.inView ? 'none' : 'translateY(20%)')};
  transition: opacity 1s cubic-bezier(0.5, 0, 0, 1) 0s,
    transform 1s cubic-bezier(0.5, 0, 0, 1) 0s;

  &:nth-child(odd) {
    flex-direction: row-reverse;
  }

  @media (max-width: 750px) {
    flex-direction: column !important;
  }

  > div:nth-child(1) {
    width: 48%;
    position: relative;
    transform: ${props =>
      props.inView ? 'none' : 'translate3d(0, 12.0745px, 0)'};

    @media (max-width: 750px) {
      width: 70%;
      margin-bottom: 80px !important;
    }
    @media (max-width: 500px) {
      width: 90%;
    }
  }

  > div:nth-child(2) {
    width: 50%;
    padding: 0 5%;
    text-align: left;
    opacity: ${props => (props.inView ? '1' : '0')};
    transform: ${props => (props.inView ? 'none' : 'translate3d(0, 30px, 0)')};
    transition: opacity 1.5s cubic-bezier(0.5, 0, 0, 1) 0.5s,
      transform 1.5s cubic-bezier(0.5, 0, 0, 1) 0.5s;

    @media (max-width: 1000px) {
      padding: 0 2%;
    }
    @media (max-width: 750px) {
      width: 75%;
      padding: 0;
      text-align: center;
    }
    span {
      color: #424143;
      font-family: Georgia;
      font-style: italic;
      font-size: 12px;
      letter-spacing: 1px;

      @media (max-width: 750px) {
        display: block;
      }
    }
    h2 {
      font-weight: normal;
      font-size: 34px;
      line-height: 1.1;
      font-family: 'NoeDisplayMedium', Georgia, serif;
      @media (max-width: 750px) {
        font-size: 26px;
        line-height: 1.3;
      }
    }
    p {
      padding-right: 5%;
      @media (max-width: 750px) {
        padding-right: 0;
      }
    }
  }

  ${ImageBG} {
    opacity: ${props => (props.inView ? '1' : '0')};
    transform: ${props => (props.inView ? 'none' : 'translate3d(0, 30px, 0)')};
    transition: opacity 1s cubic-bezier(0.5, 0, 0, 1) 0s,
      transform 1s cubic-bezier(0.5, 0, 0, 1) 0s;
  }
`;

const Office = styled(Flex)<any>`
  @media (max-width: 750px) {
    display: block !important;
  }
  > div:nth-child(1) {
    width: 50%;
    padding: 75px 8% 85px;
    opacity: ${props => (props.inView ? '1' : '0')};
    transform: ${props => (props.inView ? 'none' : 'translate3d(0, 30px, 0)')};
    transition: opacity 1s cubic-bezier(0.5, 0, 0, 1) 0s,
      transform 1s cubic-bezier(0.5, 0, 0, 1) 0s;

    @media (max-width: 750px) {
      width: 100%;
      padding: 50px 8% 60px;
    }
  }
  > div:nth-child(2) {
    opacity: ${props => (props.inView ? '1' : '0')};
    ${props => (props.inView ? 'none' : 'translate3d(0, 30px, 0)')};
    transition: opacity 1s cubic-bezier(0.5, 0, 0, 1) 0s,
      transform 1s cubic-bezier(0.5, 0, 0, 1) 0s;
    width: 50%;
    height: 100%;
    min-height: 700px;

    @media (max-width: 750px) {
      width: 100%;
      min-height: 400px;
    }
  }
`;

const AboutPage: React.FC<any> = ({ data }) => {
  const {
    title,
    principles_title,
    principles_description,
    principles,
    body,
  } = data.prismicAbout.data;
  return (
    <Layout variant="dark">
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <InView triggerOnce rootMargin="-100px 0px">
        <VideoHero data={data.prismicAbout.data} />
      </InView>

      <Container flexDirection="column">
        <Box maxWidth={1200}>
          <SectionIntro
            title={principles_title}
            heading={principles_description}
            mb={200}
            textAlign="center"
          />

          {principles.map(({ principle }: any, index: any) => {
            const {
              icon,
              title,
              heading,
              subheading,
            } = principle.document.data;

            return (
              <InView key={title} triggerOnce rootMargin="-100px 0px">
                {({ inView, ref }) => (
                  <Principle
                    ref={ref}
                    inView={inView}
                    justifyContent="space-between"
                    alignItems="center"
                    mb={100}
                  >
                    <Box maxWidth="450px">
                      <Image src={icon.url} alt="Icon" />
                      <ImageBG />
                    </Box>
                    <Box>
                      <Text
                        mb={20}
                        color="#1EAEA3"
                        fontFamily='"FFMarkWebProMedium","Helvetica",Arial,sans-serif'
                        fontSize={16}
                        letterSpacing="2.5px"
                      >
                        <span>ex. {index + 1}</span> {title.toUpperCase()}
                      </Text>
                      <Text mb={20} as="h2">
                        {heading}
                      </Text>
                      <Text as="p" fontSize={16} lineHeight={1.6}>
                        {subheading}
                      </Text>
                    </Box>
                  </Principle>
                )}
              </InView>
            );
          })}
        </Box>

        {body.map((item: any, i: number) => (
          <Fragment key={i}>
            {item.__typename === 'PrismicAboutDataBodyOffice' && (
              <InView triggerOnce rootMargin="-100px 0px">
                {({ inView, ref }) => (
                  <Office ref={ref} inView={inView} backgroundColor="#F4F2EF">
                    <Flex justifyContent="space-between" flexDirection="column">
                      <Text fontSize={50} lineHeight={1.1}>
                        {item.primary.title}
                      </Text>
                      <Text fontSize={20} lineHeight={1.6} pt={25} pb={40}>
                        <PrismicText
                          field={item.primary.description.richText}
                        />
                      </Text>
                      <Box maxWidth={150}>
                        <Image src={item.primary.icon.url} alt="Icon" />
                      </Box>
                    </Flex>
                    <Flex
                      sx={{
                        background: `url(${item.primary.image.url}) no-repeat center / cover`,
                      }}
                    />
                  </Office>
                )}
              </InView>
            )}
          </Fragment>
        ))}
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query {
    prismicAbout {
      data {
        video_hero_subtitle
        video_hero_title
        video_hero_button_text
        video_hero_poster_image {
          url
        }
        video_embed {
          html
        }
        title
        heading
        description {
          richText
        }
        principles_title
        principles_description
        principles {
          principle {
            id
            document {
              __typename
              ... on PrismicPrinciple {
                data {
                  icon {
                    url
                  }
                  title
                  heading
                  subheading
                }
              }
            }
          }
        }
        body {
          __typename
          ... on PrismicAboutDataBodyOffice {
            primary {
              title
              description {
                richText
              }
              icon {
                url
              }
              image {
                url
              }
            }
          }
        }
      }
    }
  }
`;

export default AboutPage;
